  @font-face {
    font-family: LibreFranklin-Regular;
    src: url(LibreFranklin-Regular.ttf);
  }
  
  .brand-container {
    margin-top: 35px;
    text-align: center;
    padding: 20px;
  }
  
  .brand-image {
    width: 80%;
    height: auto;
  }
  
  .social-icons {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .social-icons a {
    margin: 0 30px;
    color: #F5F5F5;
    font-size: 60px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #D46531;
  }

  .tip-container {
    margin: 20px;
    margin-left: 500px;
    margin-right: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: rgba(51, 51, 51, 0.5);
    border-radius: 10px;
    height: 100px;
  }
  
  .tip-text {
    font-size: 1.5rem;
    color: #F5F5F5;
    font-family: LibreFranklin-Regular;
    text-align: center;
    line-height: 1.5;
    margin: 0;
  }
@font-face {
  font-family: LibreFranklin-Regular;
  src: url(LibreFranklin-Regular.ttf);
}

.navbar-nav .nav-link {
  font-size: 1.1rem;
  color: #F5F5F5;
  font-family: LibreFranklin-Regular;
}

.navbar-nav .nav-link:hover {
  color: #D46531;
}

.nav-item {
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-nav {
  margin-left: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.navbar-dark .navbar-toggler-icon {
  background-color: #333;
}

.bg-custom {
  background-color: rgba(51, 51, 51, 0.5) !important;
}
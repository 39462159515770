@font-face {
  font-family: LibreFranklin-Regular;
  src: url(LibreFranklin-Regular.ttf);
}

.about-title {
  font-size: 3rem;
  color: #D46531;
  text-shadow: 5px 5px 10px #D46531;
  text-align: center;
  font-family: LibreFranklin-Regular;
  padding-top: 50px;
}

.Hello {
  color: #F5F5F5;
  text-shadow: 5px 5px 10px white;
}

#top-row {
  padding-top: 50px;
}

.about-text {
  font-size: 1.3rem;
  color: #F5F5F5;
  text-align: left;
  padding-left: 30px;
  font-family: LibreFranklin-Regular;
}

.about-image {
  max-width: 100%;
  height: auto;
  width: 70%; 
  padding-bottom: 10px;
}

.quote-container {
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: rgba(51, 51, 51, 0.5);
  border-radius: 10px;
  height: 100px;
}

.quote-text {
  font-size: 1.5rem;
  color: #F5F5F5;
  font-family: LibreFranklin-Regular;
  text-align: center;
  line-height: 1.5;
  margin: 0;
}
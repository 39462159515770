  @font-face {
    font-family: LibreFranklin-Regular;
    src: url(LibreFranklin-Regular.ttf);
  }

  .skills-section {
    padding: 20px 0;
  }
  
  .card {
    margin-bottom: 20px;
    background-color: rgba(51, 51, 51, 0.5);
    color: #F5F5F5;
    font-family: LibreFranklin-Regular;
  }

  .progress-bar {
    position: relative;
    background-color: #D46531;
    color: #F5F5F5;
    text-align: left;
    font-size: 15px;
    padding-left: 10px; 
  }

  .progress-percent {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .progress {
    height: 20px;
    background-color: rgba(51, 51, 51, 0.5);
  }
  
  .card-title {
    margin-bottom: 20px;
  }
  
  .work-card-title {
    margin-bottom: 20px;
  }

  .git-card-title {
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .contact-card-title {
    margin-bottom: 20px;
    text-decoration: underline;
  }

  .text-center {
    text-align: center;
    margin: 3%;
    color: #F5F5F5;
    font-family: LibreFranklin-Regular;
  }

  .github-section {
    padding: 20px 0;
  }
  
  .github-card-wrapper {
    border-radius: 20px; 
    overflow: hidden; 
    margin-left: 30%;
    margin-right: 30%;
    padding-top: 20px;
  }

  .contact-section {
    padding: 20px 0;
  }
  
  .contact-card-wrapper {
    border-radius: 20px; 
    overflow: hidden; 
    margin-left: 30%;
    margin-right: 30%;
    padding-top: 20px;
  }
  
  .card-link {
    text-decoration: none;
    color: inherit;
  }
  
  .horizontal-card {
    display: flex;
    flex-direction: row;
    background-color: rgba(51, 51, 51, 0.5);
    color: #F5F5F5;
    margin-bottom: 20px;
    border-radius: 20px;
    transition: transform 0.3s ease;
  }
  
  .card-img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    object-fit: cover;
    max-width: 150px; 
    margin: 10px;
    transition: transform 0.3s ease; 
  }

  .horizontal-card:hover .card-img {
    transform: scale(1.1);
  }
  
  .git-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px;
    align-items: center;
    text-align: center;
  }

  .contact-card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 35px;
    align-items: center;
    text-align: center;
  }

  .work-details {
    padding: 20px;
  }
  
  .work-details p {
    margin-bottom: 10px;
  }

  .education-section {
    padding: 20px 0;
  }
  
  .education-card-title {
    margin-bottom: 10px;
  }
  
  .school-logo {
    width: 100px;
    height: auto;
  }
  
  .education-details {
    padding-left: 20px;
  }
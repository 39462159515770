@font-face {
  font-family: LibreFranklin-Regular;
  src: url(LibreFranklin-Regular.ttf);
}

.home-section {
  padding-top: 30px;
}

.white-line {
  color: #F5F5F5;
  text-shadow: 5px 5px 10px white;
}

.image-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 80vh;
}

.image-container img {
  max-width: 80%;
  max-height: 80vh;
}

.centered-image {
  max-width: 50%;
}

.title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 80vh;
}

.title-block {
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 3rem;
  color: #D46531;
  text-shadow: 5px 5px 10px #D46531;
  text-align: center;
  font-family: LibreFranklin-Regular;
  padding-top: 20px;
}

.title-block span,
.title-block h1 {
  margin: 0 10px;
}
